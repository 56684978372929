import React from 'react';
import { Link, Paragraph, PlatformTab } from '../../../../components';

const AndroidTab = () => {
  return (
    <PlatformTab>
      <Paragraph>
        For a list of removed color variables refer to the{' '}
        <Link href="https://github.com/hudl/android-framework/pull/154/files">
          Android color file
        </Link>{' '}
        on github.
      </Paragraph>
    </PlatformTab>
  );
};

export default AndroidTab;
