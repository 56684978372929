import React from 'react';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from '../../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <Section title="About Removals">
        <Paragraph>
          Below you'll find a list of all the variables that have been removed
          from Uniform and their replacement. If you don't see a replacement
          listed, reach out to your designer or the Uniform team.
        </Paragraph>
      </Section>
      <Section>
        <Section title="Colors">
          <SectionSubhead>Tagging</SectionSubhead>
          <DataTable layout="halves">
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-tagging-content-white
                </DataTableValueBlock>
                <DataTableCodeBlock>var(--color-base-white)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-tagging-content-black
                </DataTableValueBlock>
                <DataTableCodeBlock>var(--color-base-black)</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Background</SectionSubhead>
          <DataTable layout="halves">
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-bg-level0</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level0)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-bg-level0-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level0-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-bg-level1</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level1)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-bg-level1-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level1-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-bg-level2</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level2)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-bg-level2-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level2-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-bg-level3</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level3)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-bg-level3-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level3-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-bg-level0</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level0)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-bg-level0-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level0-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-bg-level1</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level1)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-bg-level1-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level1-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-bg-level2</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level2)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-bg-level2-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level2-accent)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-bg-level3</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-bg-level3)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-bg-level3-accent
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-bg-level3-accent)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Text</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-text-contrast
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-contrast)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-text-default
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-text-subtle</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-subtle)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-text-nonessential
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-nonessential)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-text-contrast
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-contrast)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-text-default
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-text-subtle</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-subtle)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-text-nonessential
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-nonessential)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Icon</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-icon-contrast
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-contrast)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-icon-default
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-icon-subtle</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-subtle)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-icon-nonessential
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-nonessential)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-icon-contrast
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-contrast)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-icon-default
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-icon-subtle</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-subtle)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-icon-nonessential
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-content-nonessential)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Divider</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-le-divider</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-divider)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$color-de-divider</DataTableValueBlock>
                <DataTableCodeBlock>var(--color-divider)</DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Utility</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-action-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-action-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-information-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-information-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-information-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-information-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-confirmation-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-confirmation-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-confirmation-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-confirmation-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-warning-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-warning-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-warning-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-warning-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-critical-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-critical-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-critical-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-critical-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-action-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-action-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-information-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-information-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-information-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-information-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-confirmation-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-confirmation-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-confirmation-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-confirmation-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-warning-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-warning-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-warning-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-warning-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-critical-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-critical-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-critical-text
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-utility-critical-text)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-accent-action
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-accent-information
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-accent-confirmation
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-accent-warning
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-utility-accent-critical
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-accent-action
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-accent-information
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-accent-confirmation
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-accent-warning
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-utility-accent-critical
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Selection</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-outline
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-outline)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-outline-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-outline-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-bg-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-bg-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-edge
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-edge)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selection-edge-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-edge-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selectmark-unselected
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-unselected)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selectmark-unselected-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-unselected-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selectmark-selected
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-selected)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-le-selectmark-selected-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-selected-hover)
                </DataTableCodeBlock>
              </DataTableRow>

              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-outline
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-outline)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-outline-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-outline-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-bg
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-bg)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-bg-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-bg-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-edge
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-edge)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selection-edge-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selection-edge-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selectmark-unselected
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-unselected)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selectmark-unselected-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-unselected-hover)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selectmark-selected
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-selected)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-de-selectmark-selected-hover
                </DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--color-selectmark-selected-hover)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Scrim</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>
                  $color-bg-scrim-fallback
                </DataTableValueBlock>
                <DataTableValueBlock>No replacement.</DataTableValueBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Buttons</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>Remove -le- and -de-.</DataTableValueBlock>
                <DataTableValueBlock>
                  Replace btn with button
                </DataTableValueBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>

          <SectionSubhead>Links</SectionSubhead>
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>Remove -le- and -de-.</DataTableValueBlock>
                <DataTableValueBlock />
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Animations">
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-timing-fast</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--animation-duration-fast)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-timing-medium</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--animation-duration-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-timing-slow</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--animation-duration-slow)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-bg</DataTableValueBlock>
                <DataTableCodeBlock>
                  background-color var(--animation-duration-default)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-color-slow</DataTableValueBlock>
                <DataTableCodeBlock>
                  color var(--animation-duration-slow)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-color</DataTableValueBlock>
                <DataTableCodeBlock>
                  color var(--animation-duration-default)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-color-fast</DataTableValueBlock>
                <DataTableCodeBlock>
                  color var(--animation-duration-fast)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-opacity</DataTableValueBlock>
                <DataTableCodeBlock>
                  opacity var(--animation-duration-default)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-bdr-color</DataTableValueBlock>
                <DataTableCodeBlock>
                  border-color var(--animation-duration-default)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-width-slow</DataTableValueBlock>
                <DataTableCodeBlock>
                  width var(--animation-duration-slow)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-width</DataTableValueBlock>
                <DataTableCodeBlock>
                  width var(--animation-duration-default)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$trans-width-fast</DataTableValueBlock>
                <DataTableCodeBlock>
                  width var(--animation-duration-fast)
                  var(--animation-easing-static)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>

        <Section title="Type">
          <DataTable>
            <DataTableHeader>
              <DataTableRow layout="halves">
                <th>Removed Variable</th>
                <th>Stable Variable</th>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$font-sans-fallback</DataTableValueBlock>
                <DataTableCodeBlock>var(--font-body)</DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$font-weight-reg</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--font-weight-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$font-size-root</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--font-size-default)
                </DataTableCodeBlock>
              </DataTableRow>
              <DataTableRow layout="halves">
                <DataTableValueBlock>$font-size-base</DataTableValueBlock>
                <DataTableCodeBlock>
                  var(--font-size-default)
                </DataTableCodeBlock>
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
