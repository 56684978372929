import React from 'react';
import WebTab from './_code-web';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Removed Variables"
      subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Removed Variables"
      />

      <PlatformTabs
        pageType="resources"
        platforms={{
          web: <WebTab />,
          android: <AndroidTab />,
          apple: <AppleTab />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
